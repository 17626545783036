<template>
  <div class="live-info-item">
    <div class="block">
      <span>会议编号：</span>
      <span class="global_primary_text_color">{{
        meetingInfo.meet_number || "暂无"
      }}</span>
    </div>
    <div class="person block">
      <span>观看人数：</span>
      <span>{{ meetingInfo.online_count || 0 }}</span>
    </div>
    <div class="status block">
      <el-icon class="global_primary_text_color">
        <VideoCameraFilled />
      </el-icon>
      <span>{{ $config.types.meetStatus[meetingInfo.tx_meet_status] }}</span>
    </div>
  </div>
</template>
<script>
import { ElIcon } from "element-plus";
import { VideoCameraFilled } from "@element-plus/icons";
export default {
  name: "live-info",
  components: {
    ElIcon,
    VideoCameraFilled,
  },
  props: {
    meetingInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
<style scoped>
.live-info-item {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  background: #fff;
}

.block {
  display: flex;
  align-items: center;
  font-size: .9rem;
}
.number {
  font-size: 0.9rem !important;
}
.block .el-icon {
  font-size: .9rem;
}
</style>
