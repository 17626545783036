<template>
  <div class="live-container">
    <div class="live-header">
      <Header :meetingInfo="meetingInfo"></Header>
    </div>
    <div class="live-player" :style="liveStyle">
      <img
        src="https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/statics/preload.png"
        alt=""
      />
      <Player :meetingInfo="meetingInfo" ref="player" />
    </div>
    <div class="live-info">
      <LiveInfo :meetingInfo="meetingInfo" />
    </div>
    <div class="live-tab">
      <Banner :meetingInfo="meetingInfo" @changeBanner="changeBanner" />
    </div>
    <div class="live-content" ref="liveContent">
      <ItemScroll ref="ItemScroll" :isBottom="true">
        <component
          ref="coursePage"
          v-if="components"
          @initScroll="initScroll"
          v-bind:is="components"
          :meetingInfo="meetingInfo"
        ></component>
      </ItemScroll>
    </div>
    <div class="live-footer" v-if="meetingInfo.tab == 1">
      <Footer @sendMessage="sendChat" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Banner from "./components/banner";
import Footer from "./components/footer";
import Header from "./components/header";
import LiveInfo from "./components/live-info";
import Player from "../../player/player";
import websockets from "@/common/websockets";
import PopopInstance from "@/components/unit/PopupInstance";
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "live",
  components: {
    Banner,
    Footer,
    Header,
    Player,
    LiveInfo,
    ItemScroll,
  },
  data() {
    return {
      events: {
        login: "dealWithLogin",
        chat: "dealWithChat",
        online: "dealWithOnline",
        end: "dealWithEnd",
      },
      config: {
        msg: {
          welcome: {
            title: "温馨提示",
            align: "center",
            isNotFullClose: true,
            html: "<div class='popup-content-main'>专家正在讲课中，欢迎您的观看（互动区域可以点赞，送花）</div>",
            btngroup: {
              sure: true,
            },
          },
        },
      },
      isCLose: false,
      liveStyle: 0,
      meetingInfo: {
        tab: 0,
        // 0:点赞，1送花，2评论
        chat_list: [],
      },
    };
  },
  computed: {
    components() {
      let path = this.meetingInfo.tab == 0 ? "schedule" : "chat-item";
      return defineAsyncComponent(() =>
        import(`@/components/page/live/components/${path}`)
      );
    },
  },
  created() {
    this.initHeight();
    this.initMeeting();
    window.addEventListener("resize", function () {
      this.initHeight();
    });
  },
  beforeUnmount() {
    this.isCLose = true;
    websockets.close();
    window.removeEventListener("resize", () => {});
  },
  methods: {
    initHeight() {
      let width = document.body.clientWidth;
      this.liveStyle = `height:${width / (1920 / 1080).toFixed(2)}px`;
    },
    changeBanner(tab) {
      this.meetingInfo.tab = tab;
      setTimeout(() => {
        this.initScroll(tab == 0 ? true : false);
      }, 60);
    },
    initMeeting() {
      this.initWebsocket();
    },
    initWebsocket() {
      websockets.initWebsocket(
        this.$route.query.id,
        this.$config.live,
        this.receive,
        {}
      );
    },
    receive(res) {
      let key = this.events[res.type];
      console.log("receive", res.data);
      if (!key) return;
      this[key](res.data);
    },
    dealWithLogin(data) {
      this.meetingInfo = Object.assign(this.meetingInfo, data);
      this.liveContent;
      this.initScroll(true);
      this.$nextTick(() => {
        if (this.meetingInfo.tx_meet_status != 20) {
          this.checkLiveURL();
        }
      });
    },
    dealWithChat(data) {
      this.meetingInfo.chat_list.push(data);
      if (this.meetingInfo.tab == 1) this.initScroll();
    },
    dealWithOnline(data) {
      this.meetingInfo.online_count = data.online_count || 1;
    },
    dealWithEnd(data) {
      this.meetingInfo.tx_meet_status = 20;
    },
    checkLiveURL() {
      if (this.isCLose) return;
      let url = this.meetingInfo.meet_play_url;
      this.$axios
        .get(url)
        .then(() => {
          this.$nextTick(() => {
            this.$refs.player.switch(url);
            setTimeout(() => {
              this.showPopup();
            }, 3000);
          });
        })
        .catch(() => {
          this.meetingInfo.isCanPlay = false;
          setTimeout(() => {
            this.checkLiveURL();
          }, 1000);
        });
    },
    showPopup() {
      PopopInstance(this.config.msg.welcome)
        .then(() => {
          this.$nextTick(() => {
            this.meetingInfo.isCanPlay = true;
            this.meetingInfo.tx_meet_status = 10;
            this.$refs.player.play();
          });
        })
        .catch(() => {
          console.log("关闭");
        });
    },

    sendChat(data) {
      let chat = {
        type: "chat",
        data,
      };
      this.sendMessage(chat);
    },
    sendMessage(data) {
      websockets.sendMessage(data);
    },
    initScroll(status = false) {
      this.$nextTick(() => {
        this.$refs.ItemScroll.initScroll(status);
      });
    },
  },
};
</script>
<style>
.live-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f2f2f2;
  /* background:red; */
}
.live-header {
  flex: 4.5rem 0 0;
  width: 100%;
}
.live-player {
  position: relative;
}
.live-player img {
  width: 100%;
  height: auto;
  /* flex: 14.03rem 0 0;
  width: 100%; */
}
.live-info {
  flex: 2.7rem 0 0;
  width: 100%;
}
.live-tab {
  flex: 2.8rem 0 0;
  width: 100%;
}
.live-content {
  flex: 1 0 0;
  width: 100%;
  overflow: hidden;
}
.live-footer {
  flex: 3.03rem 0 0;
  width: 100%;
}
</style>
