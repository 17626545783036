<template>
  <div class="player_container">
    <div class='player_video_main global_full_screen' ref='videoMain'>
      <video ref="player" class='player_video global_full_screen  video-js vjs-big-play-centered vjs-fluid'
        x5-video-player-type="h5-page" preload="metadata" playsinline="true" webkit-playsinline="true"
        x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen="">
        <source :src="url" :type="type" />
      </video>
    </div>
    <div class="global_position global_container_center player-cover-main " v-if="meetingInfo.tx_meet_status != 10 || !meetingInfo.isCanPlay">
      {{ meetingInfo.tx_meet_status != 20 ? "正在进入直播，请您稍等片刻~" : "直播已结束，感谢您的观看" }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'player',
  props:{
    meetingInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data: () => {
    return {
      url: '',
      type: '',
      player: null,
      options: {
        controls: true,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload: 'metadata',
        muted: false,//默认情况下将使所有音频静音
        aspectRatio: "16:9",//显示比率
        sources: [
          {
            src: '',
            type: 'application/x-mpegURL'
          }
        ]
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    play() {
      this.$nextTick(() => {
        this.player.play();
      })
    },
    switch(url, type = 'application/x-mpegURL') {
      this.$nextTick(() => {
        this.url = url;
        this.type = type;
        this.options.sources[0].src = url;
        this.options.sources[0].type = type;
        let that = this;
        this.player = this.$video(this.$refs.player, this.options, function onPlayerReady() {
          this.pause()
          this.on('ended', that.playEnd)
          this.on('loadedmetadata', function () {
            // that.loaded()
          })
        })
      })
    },
    playEnd() {
      this.$emit("playEnd")
    },
    loaded() {
      this.play()
    },
  }
}
</script>
<style>
.player_container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  width:100%;
}

.player_video {
  /* width: 100%;
  height: 100%; */
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  /* width: 100%; */
}

.player_container video::-webkit-media-controls-timeline {
  display: none !important;
}

/* 全屏按钮 */
.player_container video::-webkit-media-controls-fullscreen-button {
  display: none !important;
  content: "\e719";
}

/* 音量按钮 */
.player_container video::-webkit-media-controls-mute-button {
  display: none !important;
}
.player-cover-main{
  background:#fff6f7;
}



.video-js.vjs-fluid {
  /* height:14.03rem !important */
}

.vjs-control-bar {
  /* opacity: 1;
  position: absolute;
  height:
  background-image: linear-gradient(0deg, #000, #fff); */
}

.vjs-volume-panel,
.vjs-live-control,
.vjs-picture-in-picture-control {
  display: none !important;
}

.vjs-fullscreen-control {
  position: absolute !important;
  right: 0;
  display: none !important;
}

.vjs-button>.vjs-icon-placeholder:before {
  display: inline-block;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  /* font-size: 1rem; */
}

.vjs-big-play-button {
  display: none !important;
}</style>