<template>
  <div class="live-footer-item ">
    <div class="block" @click="doSendChat(2)">
      <img :src="require('@/assets/images/live/parse-not.png')" alt="">
      <span>点赞</span>
    </div>  
    <div class="block" @click="doSendChat(3)">
      <img :src="require('@/assets/images/live/flower-not.png')" alt="">
      <span>送花</span>
    </div>
  </div>
</template>
<script>
export default {
  name:"live-footer",
  methods:{
    doSendChat(type){
      console.log(111)
      this.$emit("sendMessage",{content:"",content_type:type})
    }
  },
}
</script>
<style scoped>
.live-footer-item{
  height:100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:0 0.5rem;
  background:#fff;
}
.block{
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.block img{
  width:1.33rem;
  height:auto;
  margin-right:0.2rem;
}
.block:first-child{
  margin-right:1rem;
}
</style>