<template>
  <div class="banner-container">
    <div
      class="global_container_center banner-block"
      @click="changeBanner(0)"
      :class="`${meetingInfo.tab == 0 ? 'global_primary_text_color' : ''} `"
    >
      <div
        class="banner-text global_container_center"
        :class="`${meetingInfo.tab == 0 ? 'banner-active' : ''} `"
      >
        {{ banner[0].text }}
      </div>
    </div>
    <div class="banner-block-separator global_container_center">
      <div></div>
    </div>
    <div
      class="global_container_center banner-block"
      @click="changeBanner(1)"
      :class="`${meetingInfo.tab == 1 ? 'global_primary_text_color' : ''} `"
    >
      <div
        class="banner-text global_container_center"
        :class="`${meetingInfo.tab == 1 ? 'banner-active' : ''} `"
      >
        {{ banner[1].text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  props: {
    meetingInfo: {
      type: Object,
      default: () => {
        return {
          message: [],
        };
      },
    },
  },
  data() {
    return {
      banner: [
        {
          text: "会议海报",
          key: "chatList",
        },
        {
          text: "互动区域",
          key: "info",
        },
      ],
    };
  },
  methods: {
    changeBanner(index) {
      this.$emit("changeBanner", index);
    },
  },
};
</script>
<style scoped>
.banner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: #555;
  font-weight: 600;
  width: 100%;
  padding: 0 0.5rem;
}

.banner-block {
  flex: 1 0 0;
  height: 100%;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  position: relative;
}
.banner-text {
  height: 100%;
  width: 50%;
  position: relative;
}
.banner-active::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0.2rem;
  position: absolute;
  bottom: calc(-0.0925rem);
  right: 0;
  z-index: 1050;
  background: #e64638;
  border-radius: 0.06rem;
}
.banner-block-separator {
  flex: 0.75rem 0 0;
  height: 100%;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.banner-block-separator div {
  width: 1px;
  height: 1rem;
  background: #ccc;
}
</style>
