<template>
  <div class="live-banner-item">
    <img
      :src="require('@/assets/images/live/logo/lvzhou.png')"
      class="logo"
      alt=""
    />
    <div class="space"></div>
    <img
      alt=""
      :src="require('@/assets/images/live/logo/baiji.png')"
      class="baiji-logo"
    />
    <div class="title">
      <span class="title_text">{{ meetingInfo.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "live-banner",
  props:{
    meetingInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  }
};
</script>
<style scoped>
.live-banner-item {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.125rem;
  background: #fff;
}
.logo {
  width: 3.06rem;
  height: auto;
  padding: 0 0.66rem;
}
.baiji-logo {
  width: 4.83rem;
  height: auto;
  padding: 0 0.66rem;
}
.space {
  height: 1.3rem;
  width: 1px;
  background: rgb(172, 172, 171);
}
.title {
  height: 2.6rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.title_text{
  min-height: 1.3rem;
  max-height: 2.6rem;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.3rem;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}
</style>